<template>
  <div class="group-members-wrapper">
    <div class="action-button-wrapper">
      <div class="add-member-wrapper">
        <el-autocomplete
        class="inline-input addMemberInput"
        :fetch-suggestions="searchEmployee"
        v-model="currentInputMember"
        placeholder="Input New Group Member Name"
        :trigger-on-focus="false"
        v-if="editMode"
        ></el-autocomplete>
        <button v-if="editMode" class="btn btn-primary saveButton" @click="save">Add</button>
      </div>
      <button v-if="!editMode" class="btn btn-primary editButton" @click="startEdit">Edit</button>
      <button v-if="editMode" class="btn btn-primary saveButton" @click="save">Save</button>
    </div>

    <table class="table table-bordered">
      <thead>
        <tr>
          <th scope="col">Staff ID</th>
          <th scope="col">Name</th>
          <th scope="col">Position</th>
          <th v-if="editMode" scope="col">Remove</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="groupMember in groupMembers">
          <th scope="row">{{groupMember.employee_id}}</th>
          <td>{{groupMember.employee_name}}</td>
          <td>{{groupMember.position}}</td>
          <td v-if="editMode"><a href="#" @click="removeMember(groupMember.employee_id)">Remove</a></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';
import Hr from '@/lib/hr';

export default {
  name: 'GroupMembers',
  data(){
    return {
      editMode: false,
      groupMembers: [],
      currentInputMember: '',
    };
  },
  methods: {
    startEdit(){
      this.editMode = true;
    },
    removeMember(employeeId){
      const newGroupMembers = this.groupMembers.filter(function(item) {
        return item.employee_id !== employeeId;
      });
      this.groupMembers = newGroupMembers;
    },
    async save(){
      const memberName = this.currentInputMember;
      const employee = await this.getEmployeeByName(memberName);
      if(employee){
        this.groupMembers.push(employee);
        this.currentInputMember = '';
      }
      this.$emit('updateMemberList', this.groupMembers);
      this.editMode = false;
    },
    async getEmployeeByName(memberName){
      const loginInfo = Common.getLoginInfo();
      const searchFields = {
        'employee_name': memberName,
      };
      const employees = await Hr.searchEmployee(this.apiUrl, searchFields, loginInfo);
      const employee = employees[0];
      return employee;
    },
    /* Employee Filter */
    createEmployeeFilter(queryString) {
      return (employee) => {
        return (employee.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },

    /* For auto select */
    async searchEmployee(queryString, cb){
      const loginInfo = Common.getLoginInfo();
      const employees = await Hr.loadAllEmployees(this.apiUrl, loginInfo);
      const employeeSuggest = [];

      for(let i = 0; i < employees.length; i++){
        const employee = employees[i];
        const employeeSelection = {
          'value': employee.employee_name,
          'name': employee.employee_name
        };
        employeeSuggest.push(employeeSelection);
      }

      const results = queryString ? employeeSuggest.filter(this.createEmployeeFilter(queryString)) : employees;

      cb(results);
    },
  },
  watch: {
    'currentEmployeeGroup': function(){
      if(this.currentEmployeeGroup.members){
        this.groupMembers = this.currentEmployeeGroup.members;
      }
    }
  },
  computed: mapState({
    currentEmployeeGroup: state => state.hr.currentEmployeeGroup,
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style lang="scss">
.group-members-wrapper{
  .action-button-wrapper{
    margin-bottom: 0;
    justify-content: flex-start;
    margin-bottom: 50px;
  }
  .add-member-wrapper{
    display: flex;
    align-items: center;
    flex: 1;
  }
  .addMemberInput{
    margin-right: 20px;
  }
}
</style>
