<template>
  <div class="content-wrapper">
    <div class="employee main-content">
      <div class="page-header">
        <h1>Employee Group Information</h1>
        <EmployeeGroupForm :viewMode="true" :eventName="'updateEmployeeGroup'" @updateEmployeeGroup="updateEmployeeGroup"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import EmployeeGroupForm from '@/components/hr/EmployeeGroupForm.vue';
import Common from '@/lib/common';
import Hr from '@/lib/hr';

export default {
  name: 'EmployeeGroup',
  components: {
    EmployeeGroupForm,
  },
  mounted(){
    this.loadEmployeeGroup();
  },
  methods:{
    async loadEmployeeGroup(){
      const loginInfo = Common.getLoginInfo();
      try{
        const groupId = this.$route.params.id;
        const employeeGroup = await Hr.loadEmployeeGroup(this.apiUrl, groupId, loginInfo);
        this.setCurrentEmployeeGroup(employeeGroup);
      }catch(err){
        console.log(err);
      }
    },
    async updateEmployeeGroup(form){
      try{
        const loginInfo = Common.getLoginInfo();
        const groupId = this.$route.params.id;
        const employeeGroup = await Hr.updateEmployeeGroup(this.apiUrl, groupId, form, loginInfo);
      }catch(err){
        console.log(err);
      }
    },
    ...mapActions(['setCurrentEmployeeGroup']),
  },
  computed: mapState({
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style lang="scss">
.page-header{
  h1{
    margin-bottom: 50px;
  }
}
</style>
