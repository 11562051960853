<template>
  <div class="form-wrapper">
    <div class="action-button-wrapper">
      <button v-if="disabled" class="btn btn-primary editButton" @click="startEdit">Edit</button>
      <button v-if="!disabled" class="btn btn-primary saveButton" @click="save">Save</button>
    </div>
    <div class="card info-card">
      <el-form ref="group-form" :disabled="disabled" :model="form" label-width="200px">
        <div class="row">
          <el-form-item label="Group Name" :required="true">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="Group Type" style="max-width: 550px;" :required="true">
            <el-select v-model="form.groupType" placeholder="Select the group type">
              <el-option v-for="groupType in groupTypes" :key="groupType.id" :label="groupType.name" :value="groupType.id"></el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
    </div>

    <div class="group-members-info">
      <h2>Group Member List</h2>
      <div class="card info-card">
        <GroupMembers @updateMemberList="updateMemberList" />
      </div>
    </div>

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import GroupMembers from '@/components/hr/GroupMembers.vue';
import Common from '@/lib/common';
import Hr from '@/lib/hr';

export default {
  name: 'EmployeeGroupForm',
  components: {
    GroupMembers,
  },
  props:{
    viewMode: Boolean,
    eventName: String,
  },
  data(){
    let disabled = false;

    //Default form value
    let form = {
      name: '',
      groupType: '',
      groupMembers: [],
    };

    //If view mode is enabled, disable the form
    if(this.viewMode === true){
      disabled = true;
    };

    return {
      groupTypes: [],
      disabled,
      form,
    };
  },
  mounted(){
    if(this.viewMode === true){
      $('.group-members-wrapper .editButton').css('display', 'none');
    }
    this.loadEmployeeGroupType();
  },
  methods:{
    /* For group type selection */
    async loadEmployeeGroupType(){
      const loginInfo = Common.getLoginInfo();

      try{
        const groupTypes = await Hr.loadEmployeeGroupType(this.apiUrl, loginInfo);
        this.groupTypes = groupTypes;
      }catch(err){
        console.log(err);
      }
    },
    startEdit(){
      $('.group-members-wrapper .editButton').css('display', 'block');
      this.disabled = false;
    },
    updateMemberList(memberList){
      this.form.groupMembers = memberList;
    },
    save(){
      $('.group-members-wrapper .editButton').css('display', 'none');
      this.$emit(this.eventName, this.form);
      this.disabled = true;
    },
  },
  watch: {
    'currentEmployeeGroup': function(){
      this.form = {
        name: this.currentEmployeeGroup.name,
        groupType: this.currentEmployeeGroup.type,
        groupMembers: this.currentEmployeeGroup.members,
      };
    }
  },
  computed: mapState({
    currentEmployeeGroup: state => state.hr.currentEmployeeGroup,
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style lang="scss">
.info-card{
  padding-top: 30px;
  padding-right: 30px;
}

.group-members-info{
  margin-top: 30px;
  .info-card{
    padding-left: 30px;
  }
  h2{
    margin-bottom: 20px;
  }
}

.action-button-wrapper{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 50px;

  .btn{
    margin-right: 20px;
    &:last-child{
      margin: 0;
    }
  }
}
</style>
